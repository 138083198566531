<template>
  <div class="container" dir="rtl">
    <div class="row mt-3 mb-3">
      <div class="col-sm">
        <div class="no-gutters align-items-center">
          <div class="input-group borders">
            <div class="input-group-start" id="btn_search">
              <svg xmlns="http://www.w3.org/2000/svg" width="16" height="16" fill="currentColor"
                   class="bi bi-search me-2 mt-1"
                   viewBox="0 0 16 16">
                <path
                    d="M11.742 10.344a6.5 6.5 0 1 0-1.397 1.398h-.001c.03.04.062.078.098.115l3.85 3.85a1 1 0 0 0 1.415-1.414l-3.85-3.85a1.007 1.007 0 0 0-.115-.1zM12 6.5a5.5 5.5 0 1 1-11 0 5.5 5.5 0 0 1 11 0z"/>
              </svg>
            </div>
            <input @input="onSearch($event)" v-model="query" class="form-control" type="search"
                   placeholder="ابحث عن المدرسة..." id="search">

          </div>
          <div class="col-auto">
            <button class="btn btn-outline-light text-dark border-0 rounded-pill ml-n5" type="button">
              <i class="fa fa-search"></i>
            </button>
          </div>
        </div>
      </div>
      <div class="col-sm mb-2">
        <select @change="onBacChange($event)" v-model="bacId"
                class="form-select border-secondary rounded-pill text-black" aria-label="Default select example">
          <option selected :value="-1" :key="-1">-- جميع الشعب --</option>
          <option v-for="bac in bacs" :value="bac.id" :key="bac.id">{{ bac["name"] }}</option>
        </select>
      </div>
      <div class="col-sm mb-2">
        <select @change="onSectorChange($event)" v-model="sectorId"
                class="form-select border-secondary rounded-pill text-black" aria-label="Default select example">
          <option selected :value="-1" :key="-1">-- جميع القطاعات --</option>
          <option v-for="sector in sectors" :value="sector.id" :key="sector.id">{{ sector["name"] }}</option>
        </select>
      </div>
    </div>
    <div class="mb-3" style="padding-bottom: 4rem">
      <div v-if="loading" class="text-center">
        <div class="spinner-border spinner-border-lg text-primary" role="status" style="text-align: center;">
          <span class="sr-only"></span>
        </div>
      </div>
      <div v-else-if="unis.length === 0" class="" role="status" style="text-align: center;">
        <img src="https://www.beloud.com/_nuxt/img/nothing-was-found.05824b4.svg" alt="No Results Found">
        <h1>لم يتم العثور على نتائج</h1>
        <p>لم نتمكن من العثور على أي شيء مما تبحث عنه ، يرجى المحاولة مرة أخرى</p>
      </div>
      <div v-else class="container">
        <div class="row gy-4">
          <div class="col-xl-4 col-lg-6 d-flex align-self-stretch" v-for="uni in unis" :key="uni.id">
            <UniItemView :uni="uni"/>
          </div>
        </div>
      </div>
    </div>
    <transition name="fade">
      <ScrollToBottom v-if="isTopVisible" @click="toTop"/>
    </transition>

  </div>

</template>

<script>
// @ is an alias to /src

import UniItemView from '@/components/UniItemView.vue';
import {searchUnis, searchLocalUnis, getSectors, getBacs} from '@/firebase/MyService.js'
import ScrollToBottom from "@/components/ScrollToBottom.vue";

export default {
  name: "HomeView",
  components: {ScrollToBottom, UniItemView},
  data() {
    return {
      loading: false,
      unisAll: [],
      unis: [],
      sectors: [],
      bacs: [],
      query: "",
      sectorId: -1,
      bacId: -1,
      scTimer: 0,
      isTopVisible: false,

    }

  },
  created () {
    window.addEventListener('scroll', this.scrollFunction);
  },
  unmounted () {
    window.removeEventListener('scroll', this.scrollFunction);
  },
  mounted() {
    this.loadUnis()
    this.loadSectors()
    this.loadBacs()
  },
  methods: {
    async loadUnis() {
      this.loading = true
      this.unis = []
      if (this.unisAll.length !== 0) {
        this.unis = await searchLocalUnis(this.unisAll, this.query, this.sectorId, this.bacId);
      } else {
        this.unis = await searchUnis(this.query, this.sectorId, this.bacId);
        this.unisAll = this.unis;
      }
      this.loading = false
    },
    async loadSectors() {
      this.sectors = await getSectors();
      console.log(this.sectors)
    },
    async loadBacs() {
      this.bacs = await getBacs();
    },
    onBacChange(event) {
      console.log(event)
      this.loadUnis()
    },
    onSectorChange(event) {
      console.log(event)
      this.loadUnis()
    },
    onSearch(event) {
      console.log(event)
      this.loadUnis()
    },
    toTop() {
      window.scrollTo({
        top: 0,
        left: 0,
        behavior: 'smooth'
      })
    },
    scrollFunction() {
      console.log("scrollFunction")
      this.isTopVisible = document.body.scrollTop > 20 || document.documentElement.scrollTop > 20
    }
  }
};
</script>

<style scoped>

.borders {
  border-radius: 25px;
  border: 2px solid #1DA1F2;
}

#search {
  border: 0;
  border-radius: 25px;
}

#search:focus {
  border-color: inherit;
  -webkit-box-shadow: none;
  box-shadow: none;
}

#btn_search {
  padding: 0%;
}
</style>