<template>
  <div class="container">
    <div class="mb-4 ms-2 me-2 d-flex justify-content-between align-items-center" dir="RTL">
      <h1>أسئلة وأجوبة</h1>
      <OpenWhatsapp />

    </div>

    <div class="mb-4 ms-2 me-2" dir="RTL">
      <div class="mb-1" v-for="section in sections" :key="section.id">
        <UniPageSection :section="section"/>
      </div>
    </div>
  </div>


</template>
<script>
import {getQAData} from '@/firebase/MyService.js'
import UniPageSection from '@/components/UniPageSection.vue';
import OpenWhatsapp from "@/components/OpenWhatsapp.vue";

export default {
  components: {OpenWhatsapp, UniPageSection},
  data() {
    return {
      sections: []
    }
  }, mounted() {
    this.loadData()
  }, methods: {
    async loadData() {
      this.sections = await getQAData()
    }
  }
}
</script>
