<template>
    <div class="container" dir="rtl">
        <h4 style="text-align:justify"><span style="font-family:Calibri,sans-serif"><span style="color:#000000">ما هي
                    خطتك لما بعد
                    البكالوريا؟</span></span></h4>
        <br />
        <h4 style="text-align:justify"><span style="font-family:Calibri,sans-serif"><span style="color:#000000">&nbsp;لم
                    تفكر فيها بعد !!ماذا
                    تنتظر؟ حتى تحصل على البكالوريا؟!</span></span></h4>
        <br />
        <p style="text-align:justify"><span style="font-size:14pt"><span style="font-family:Calibri,sans-serif"><span
                        style="color:#000000">انتبه! فَفِي الشهور المقبلة
                        سيتوالى ظهور المباريات واعلانات التسجيل في مختلف مؤسسات التعليم العالي وتكوين الأطر، وفي حال لم
                        تقم بتحديد أهدافك فقد تواجه الحيرة والتردد وفقدان القدرة على اتخاذ القرار المناسب...وهناك من
                        يضيع عاما كاملا بعد الباك حائرا مترددا (سواء حصل على معدل مقبول او مرتفع)!</span></span></span>
        </p>

        <p style="text-align:justify"><span style="font-size:13.999999999999998pt"><span
                    style="font-family:Calibri,sans-serif"><span style="color:#000000">وحتى لا تسقط في هذا المأزق
                        ...ندعوك لتخصيص بعض الوقت للتفكير في خطتك لما بعد البكالوريا عبر الاجابة على بعض
                        الاسئلة:</span></span></span></p>
        <br />
        <p style="text-align:justify"><span style="font-size:13.999999999999998pt"><span
                    style="font-family:Calibri,sans-serif"><span style="color:#000000">ما هي المؤسسات العليا المتاحة لي
                        بعد البكالوريا؟ وما هي شروط ولوجها وماهي كيفية الترشح اليها ونظام الدراسة بها
                        وآفاقها...؟</span></span></span></p>
        <br />
        <p style="text-align:justify"><span style="font-size:13.999999999999998pt"><span
                    style="font-family:Calibri,sans-serif"><span style="color:#000000">من بين هذه المؤسسات ما هي تلك
                        التي تناسب قدراتي (نتائجي الدراسية)؟ وميولاتي وطموحاتي الدراسية والمهنية؟</span></span></span>
        </p>
        <br />
        <p style="text-align:justify"><span style="font-size:13.999999999999998pt"><span
                    style="font-family:Calibri,sans-serif"><span style="color:#000000">إذا كانت الخطة &quot; أ &quot; هي
                        الترشح لهذه المؤسسات العليا التي حددتها والتي تشترط الحصول على معدل جيد في البكالوريا، فما هي
                        الخطة &quot; ب &quot; في حال لم تتمكن من ولوج هذه المؤسسات... يعني ما هو البديل...؟ يجب ان تحدد
                        بدائل لكيلا تقع فريسة للحيرة والتيه...</span></span></span></p>
        <br />
        <p style="text-align:justify"><span style="font-size:13.999999999999998pt"><span
                    style="font-family:Calibri,sans-serif"><span style="color:#000000">تطبيق &quot;مستقبلي&quot; يهدف
                        إلى مساعدتك على الإجابة على هذه الأسئلة من خلال تعريفك بالفرص الدراسية والتكوينية المتاحة لك بعد
                        البكالوريا، ومن ثمّ تمكينك من بلورة وتحقيق مشروعك الشخصي الدراسي والمهني.</span></span></span>
        </p>
        <br />
        <p style="text-align:justify"><span style="font-size:13.999999999999998pt"><span
                    style="font-family:Calibri,sans-serif"><span style="color:#000000"><strong> وتجدر الإشارة إلى أننا
                            اعتمدنا في إنجاز هذا الدليل على المعلومات الواردة في المذكرات الوزارية وإعلانات المباريات
                            الصادرة خلال الموسم الجامعي 2021-2022، ولذلك فهي قابلة للتغيير والتعديل مما يستوجب عليك
                            التتبع المستمر لصدور إعلانات المباريات الجديدة لسنة 2023 والتي تظهر في مواقع تلك المؤسسات
                            على الأنترنت، إضافة إلى التواصل مع مستشار(ة) التوجيه التربوي في مؤسستك لمزيد من المعلومات
                            والتوضيحات. </strong></span></span></span></p>
        <p>&nbsp;</p>
    </div>


</template>
<script>
export default {

}
</script>
<style >

</style>