<template>
  <div class="container-fluid" dir="rtl">
    <div class="mb-3" style="padding-bottom: 4rem">
      <div v-if="loading" class="text-center">
        <div class="spinner-border spinner-border-lg text-primary" role="status" style="text-align: center;">
          <span class="sr-only"></span>
        </div>
      </div>
      <div v-else-if="concours.length === 0" class="" role="status" style="text-align: center;">
        <img src="https://www.beloud.com/_nuxt/img/nothing-was-found.05824b4.svg" alt="No Results Found">
        <h1>لم يتم العثور على نتائج</h1>
        <p>لم نتمكن من العثور على أي شيء مما تبحث عنه ، يرجى المحاولة مرة أخرى</p>
      </div>
      <div v-else class="mt-2" v-for="concour in concours" :key="concour.id">
        <ConcourItemView :concour="concour"/>
      </div>
    </div>

    <transition name="fade">
      <ScrollToBottom v-if="isTopVisible" @click="toTop"/>
    </transition>
  </div>
</template>
<script>
import ConcourItemView from '@/components/ConcourItemView.vue';
import {getConcours} from '@/firebase/MyService.js'
import ScrollToBottom from "@/components/ScrollToBottom.vue";

export default {
  name: "ConcoursView",
  components: {ScrollToBottom, ConcourItemView},
  data() {
    return {
      loading: false,
      concours: [],
      isTopVisible: false,
    }

  },
  created () {
    window.addEventListener('scroll', this.scrollFunction);
  },
  unmounted () {
    window.removeEventListener('scroll', this.scrollFunction);
  },
  mounted() {
    this.loadConcours()
  },
  methods: {
    async loadConcours() {
      this.loading = true
      this.concours = []
      this.concours = await getConcours();
      this.loading = false
    },
    toTop() {
      window.scrollTo({
        top: 0,
        left: 0,
        behavior: 'smooth'
      })
    },
    scrollFunction() {
      console.log("scrollFunction")
      this.isTopVisible = document.body.scrollTop > 20 || document.documentElement.scrollTop > 20
    }
  }
}
</script>
<style></style>