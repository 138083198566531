import { initializeApp } from "firebase/app"
import { getFirestore } from "firebase/firestore";
import { getAnalytics } from "firebase/analytics";
const firebaseConfig = {
  apiKey: "AIzaSyDyWSM609GcTuRSJ9UKYSRvtiWkIeLD0Dk",
  authDomain: "najahi-f9079.firebaseapp.com",
  projectId: "najahi-f9079",
  storageBucket: "najahi-f9079.appspot.com",
  messagingSenderId: "935216926354",
  appId: "1:935216926354:web:b21968966ba3aeae39604b",
  measurementId: "G-S42M1SDVEP"
};


const app = initializeApp(firebaseConfig);

export const analytics = getAnalytics(app);
export const db = getFirestore()
