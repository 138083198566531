<template>
    <div dir="rtl" class="collapsible" type="button" data-bs-toggle="collapse" :data-bs-target="'#c' + section.order"
        aria-expanded="false" aria-controls="collapseExample" :href="'#' + section.id">
        <div class="row">
            <h5 class="col-sm">
                {{ section.title }}
                <svg width="24" height="24" xmlns="http://www.w3.org/2000/svg" viewBox="0 0 24 24">
                    <path d="M7 10l5 5 5-5z" />
                    <path d="M0 0h24v24H0z" fill="none" />
                </svg>
            </h5>

        </div>
    </div>

    <div :id="'c' + section.order" class="collapse">
        <div dir="RTL" class="card card-body" v-html="section.desc">

        </div>
    </div>
</template>
<script>

export default {
    props: {
        section: {}
    }

}
</script>
<style scoped>
#card {
    border-color: inherit;
    -webkit-box-shadow: none;
    box-shadow: none;
}

.collapsible {
    background-color: #bde3ff;
    color: #444;
    cursor: pointer;
    padding: 12px;
    width: 100%;
    border: none;
    outline: none;
    font-size: 15px;
}
</style>