import { db } from './init.js'
import { collection, getDocs, getDoc, query, doc } from 'firebase/firestore'

const collectionUnisId = "unis"
const collectionSectorsId = "sectures"
const collectionBacsId = "bacs"
const collectionQAId = "q_a"
const collectionConcoursId = "concours"

const getPhotoGoogleDrive = (photo) => {
    if(photo.includes("drive.google.com")){
        let start = photo.indexOf("d/") + 2
        let end = photo.indexOf("/view")
        let photoId = photo.substring(start, end)
        return `https://lh3.google.com/u/0/d/${photoId}`
    } else{
        return photo
    }

}


export const getUni = async id => {
    const docRef = doc(db, collectionUnisId, id)
    const uni = await getDoc(docRef)
    if (uni.exists) {
        let obj = uni.data()
        obj["id"] = uni.id
        obj["photo"] = getPhotoGoogleDrive(obj["photo"])
        return obj;
    } else {
        return null
    }

}

export const getConcour = async id => {
    const docRef = doc(db, collectionConcoursId, id)
    const uni = await getDoc(docRef)
    if (uni.exists) {
        let obj = uni.data()
        obj["id"] = uni.id
        obj["poster"] = getPhotoGoogleDrive(obj["poster"])
        return obj;
    } else {
        return null
    }

}

export const getUniData = async id => {
    let array = []
    const col = collection(db, collectionUnisId+"/"+id+"/sections")
    const docs = await getDocs(col)
    docs.forEach((doc) => {
        const section = doc.data()
        section["id"] = doc.id
        array.push(section)
        
    });
    array.sort(function (a, b) { return a["order"] - b["order"] });

    return array;
}

export const searchUnis = async (query, sectorId, bacId) => {
    let array = []
    const querySnapshot = await getDocs(collection(db, collectionUnisId));
    querySnapshot.forEach((doc) => {
        const uni = doc.data()
        uni["id"] = doc.id
        let isValid = true
        if (query !== "") isValid = isValid && (query.includes(uni["name"]) || uni["abriviation"].toLowerCase().indexOf(query) !== -1)
        if (sectorId !== -1) isValid = isValid && (sectorId === uni["secture"])
        if (bacId !== -1) isValid = isValid && (uni["bacs"].includes(bacId))

        if (isValid) {
            uni["photo"] = getPhotoGoogleDrive(uni["photo"])
            array.push(uni)
        }
    });


    array.sort(function(a, b) {
        if (a["orderInSector"] !== b["orderInSector"]) {
            return a["orderInSector"] - b["orderInSector"];
        } else {
            return b["bacplus"] - a["bacplus"];
        }
    });



    return array
}

export const searchLocalUnis = async (local, query, sectorId, bacId) => {
    query = query.toLowerCase()
    let array = []
    local.forEach((uni) => {
        let isValid = true
        if (query !== "") isValid = isValid && (query.includes(uni["name"]) || uni["abriviation"].toLowerCase().indexOf(query) !== -1)
        if (sectorId !== -1) isValid = isValid && (sectorId === uni["secture"])
        if (bacId !== -1) isValid = isValid && (uni["bacs"].includes(bacId))

        if (isValid) {
            uni["photo"] = getPhotoGoogleDrive(uni["photo"])
            array.push(uni)
        }
    });

    array.sort(function (a, b) { return a["bacplus"] - b["bacplus"] });


    return array
}

export const getSectors = async () => {
    let array = []
    let result = await getDocs(query(collection(db, collectionSectorsId)));
    result.forEach((doc) => {
        let obj = doc.data()
        obj["id"] = doc.id
        array.push(obj)
    })
    return array
}

export const getBacs = async () => {
    let array = []
    let result = await getDocs(query(collection(db, collectionBacsId)));
    result.forEach((doc) => {
        let obj = doc.data()
        obj["id"] = doc.id
        array.push(obj)
    })
    return array
}

export const getConcours = async () => {
    let array = []
    let result = await getDocs(query(collection(db, collectionConcoursId)));
    result.forEach((doc) => {
        let obj = doc.data()
        obj["id"] = doc.id
        obj["poster"] = getPhotoGoogleDrive(obj["poster"])
        array.push(obj)
    })
    array.sort(function (a, b) { return b["created"] - a["created"] });

    return array
}

export const getQAData = async () => {
    let array = []
    const col = collection(db, collectionQAId)
    const docs = await getDocs(col)
    docs.forEach((doc) => {
        const section = doc.data()
        section["id"] = doc.id
        array.push(section)
    });
    array.sort(function (a, b) { return a["order"] - b["order"] });

    return array;
}