<template>
    <div class="container-fluid" dir="rtl">
        <div class="row mt-3 mb-4">
            <div class="col-sm-4">
                <img class="card-img img-wrap" :src="uni['photo']" alt="" />
            </div>
            <div class="col-sm">
                <h1 class="card-title">{{ uni['abriviation'] }}</h1>
                <h2>{{ uni['name'] }}</h2>
                <div class="mb-2"><a class="card-text" :href="'//' + uni['website']" target="_blank">{{ uni['website']
                }}</a></div>
            </div>

        </div>

        <div class="mb-4">
            <div class="mb-1" v-for="section in sections" :key="section.id">
                <UniPageSection :section="section" />
            </div>
        </div>

    </div>
</template>
<script>
import { getUni, getUniData } from '@/firebase/MyService.js'
import UniPageSection from '@/components/UniPageSection.vue';

export default {
    components: { UniPageSection },
    data() {
        return {
            uni: {},
            sections: [],
            id: this.$route.params.id
        }
    }, mounted() {
        this.load()
        this.loadData()
    },
    methods: {
        async load() {
            this.uni = await getUni(this.id)
        },

        async loadData() {
            this.sections = await getUniData(this.id)

        }
    }
}
</script>
<style scoped>
.img-wrap {
    width: 100%;
    height: 260px;
    position: relative;
    display: inline-block;
    overflow: hidden;
    margin: 0;
    background-color: gray;
    border-radius: 1rem;
    overflow: hidden;
}

div>img {
    display: block;
    position: absolute;
    top: 50%;
    left: 50%;
    min-height: 100%;
    min-width: 100%;
    transform: translate(-50%, -50%);
}
</style>