<template>
    <div>
        <div class="container-fluid" dir="rtl">
            <div class="row mt-3 mb-4">
                <div class="col-sm">
                    <h1 class="card-title" style="text-align: center;">{{ concour['title'] }}</h1>
                </div>

            </div>

            <div class="mb-4">
                <div v-html="concour['content']">
                </div>
            </div>

        </div>
    </div>
</template>
<script>
import { getConcour } from '@/firebase/MyService.js'

export default {
    data() {
        return {
            html: "",
            concour: {},
            id: this.$route.params.id
        }
    }, mounted() {
        this.load()
    },
    methods: {
        async load() {
            let concour = await getConcour(this.id)
            let html = this.change(concour['content'])
            concour['content'] = html
            this.concour = concour
        }, change(html) {
            const parser = new DOMParser();
            const document = parser.parseFromString(html, 'text/html');
            const imgTags = document.getElementsByTagName('img');

            // Iterate through all the img tags and change the src attribute
            for (let i = 0; i < imgTags.length; i++) {
                let src = imgTags[i].src;
                if (src.includes("drive.google.com")) {
                    imgTags[i].src = this.convertGoogleDriveLink(src)
                }
                imgTags[i].style = "border: 2px solid #000; display: block; margin: 0 auto 20px; max-width: 80%; max-height: 50%;"
            }

            return document.documentElement.innerHTML
                }, convertGoogleDriveLink(googleDriveLink) {
            const fileId = googleDriveLink.match(/[-\w]{25,}/);
            if (fileId) {
                const usableLink = `https://drive.google.com/uc?id=${fileId[0]}`;
                return usableLink;
            } else {
                // Invalid Google Drive link
                return null;
            }
        }
    }
}
</script>
<style scoped>
img {
    border: 2px solid #000; 
    display: block;
    margin: 0 auto 20px;
}
</style>