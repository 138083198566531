import { createRouter, createWebHistory } from "vue-router";
import HomeView from "../views/HomeView.vue";
import UniPageView from "../views/UniPageView.vue";
import QAView from "../views/QAView.vue";
import NoticeView from "../views/NoticeView.vue";
import ConcoursView from "../views/ConcoursView.vue";
import ConcourPageView from "../views/ConcourPageView.vue";


const routes = [
  {
    path: "/concours",
    name: "concours",
    component: ConcoursView,
  },{
    path: '/concours/:id',
    name: 'ConcourPageView',
    component: ConcourPageView
  },
  {
    path: "/",
    name: "home",
    component: HomeView,
  },
  {
    path: '/unis/uni/:id',
    name: 'UniPageView',
    component: UniPageView
  },
  {
    path: "/qa",
    name: "qa",
    component: QAView,
  },{
    path: "/notice",
    name: "notice",
    component: NoticeView,
  },
  {
    path: "/about",
    name: "about",
    // route level code-splitting
    // this generates a separate chunk (about.[hash].js) for this route
    // which is lazy-loaded when the route is visited.
    component: () =>
      import(/* webpackChunkName: "about" */ "../views/AboutView.vue"),
  },{
    path: "/support",
    name: "support",
    component: () =>
        import(/* webpackChunkName: "about" */ "../views/SupportView.vue"),
  }, {
    path: "/privacy-policy",
    name: "privacy-policy",
    component: () =>
        import(/* webpackChunkName: "about" */ "../views/PrivacyPolicyView.vue"),
  },
  {
    path: "/privacy-policy/en",
    name: "privacy-policy-en",
    component: () =>
        import(/* webpackChunkName: "about" */ "../views/PrivacyPolicyEnView.vue"),
  },
];

const router = createRouter({
  history: createWebHistory(process.env.BASE_URL),
  routes,
});

export default router;
