<template>
<div class="w-100 d-flex h-100">
  <div class="card w-100">
    <router-link id="r" :to="{ name: 'UniPageView',params: {id: uni.id} }" class="d-flex flex-column h-100">
    <img :src="uni['photo']" :alt="uni['name']" class="card-img-top img-wrap">
    <div class="card-body">
      <h4 class="card-title">{{ uni['name'] }}</h4>
    </div>
    </router-link>

    <div class="card-footer d-flex flex-wrap">
      <a :href="uni['website']" target="_blank" class="flex-grow-1">{{ uni['website'] }}</a>
      <h6 class="mb-0"><span class="badge bg-secondary">{{ uni['abriviation'] }}</span></h6>
    </div>
  </div>
</div>


</template>
<script>
export default {
  data() {
    return {}
  },
  props: {
    uni: {}
  },

  methods: {}
}
</script>
<style scoped>
.card {
  border-radius: 15px;
  -webkit-box-shadow: 0 1px 1px rgba(72,78,85,.6);
  box-shadow: 0 1px 1px rgba(72,78,85,.6);
  -webkit-transition: all .2s ease-out;
  -moz-transition: all .2s ease-out;
  -ms-transition: all .2s ease-out;
  -o-transition: all .2s ease-out;
  transition: all .2s ease-out;
}
.img-wrap {
  width: 100%;
  height: 260px;
  position: relative;
  display: inline-block;
  overflow: hidden;
  margin: 0;
  background-color: gray;
  border-radius: 1rem 1rem 0 0 ;
}

.card:hover{
  -webkit-box-shadow:0 12px 12px rgb(135,206,250);
  box-shadow: 0 12px 12px rgb(135,206,250);
  -webkit-transform: translateY(-15px);
  -moz-transform: translateY(-15px);
  -ms-transform: translateY(-15px);
  -o-transform: translateY(-15px);
  transform: translateY(-15px);
}


#r {
  display: inline-block;
  text-decoration: none;
  color: black;
}
</style>