<template>
    <div class="card">
        <router-link id="r" :to="{ name: 'ConcourPageView',params: {
                            id: concour.id
                        } }">
            <div class="row no-gutters">
                <div class="col-sm-3">
                    <img class="card-img img-wrap" :src="concour['poster']" alt="">
                </div>
                <div class="col-sm-8">
                    <div class="card-body">
                        <h4 class="card-title">{{concour['title']}}</h4>
                    </div>
                </div>
            </div>
        </router-link>

    </div>
</template>
<script>
export default {
    data() {
        return {

        }
    },
    props: {
        concour: {}
    },

    methods: {

    }
}
</script>
<style scoped>
.img-wrap {
    width: 100%;
    height: 150px;
    position: relative;
    display: inline-block;
    overflow: hidden;
    margin: 0;
    background-color: gray;
}

div>img {
    display: block;
    position: absolute;
    top: 50%;
    left: 50%;
    min-height: 100%;
    min-width: 100%;
    transform: translate(-50%, -50%);
}


#r {
    display: inline-block;
    text-decoration: none;
    color: black;
}
</style>