<script>
export default {
  name: "ScrollToBottom",
}
</script>

<template>

  <div class="scroll-to-top">

    <div class="button p-2">
      <svg enable-background="new 0 0 512 512" fill="#ffffff" height="200px" width="200px" id="Layer_1" version="1.0"
           viewBox="0 0 512 512" xml:space="preserve" xmlns="http://www.w3.org/2000/svg"><g><polygon points="402.8,361.2 256,214.4 109.2,361.2 66.8,318.8 256,129.6 445.2,318.8  "/></g></svg>
    </div>
  </div>
</template>

<style scoped>
.scroll-to-top {
  position: fixed;
  bottom: 20px;
  right: 20px;
}

.button {
  width: 50px;
  height: 50px;
  background-color: #007bff; /* Change to your preferred color */
  border-radius: 50%;
  display: flex;
  justify-content: center;
  align-items: center;
  cursor: pointer;
  transition: background-color 0.3s ease;
}

.button:hover {
  background-color: #0056b3; /* Change to your preferred hover color */
}

.arrow-up {
  width: 0;
  height: 0;
  border-left: 10px solid transparent;
  border-right: 10px solid transparent;
  border-bottom: 15px solid white; /* Change to your preferred arrow color */
}
</style>